.login-container {
  max-width: 465px;
  margin: 20px 0.85em;
}
#login-wrapper {
  border: solid 1px var(--neutral-medium);
  padding: 15px;
}
@media screen and (min-width: 495px) {
  .login-container {
    margin: 20px auto;
  }
}
.signin-container  {
  max-width: 465px;
  margin: 0 auto;
}
.signin-view-wider {
  display: none;
}
.registered-guest {
  padding: 30px;
}
#booking-guest {
  padding: 30px 30px 30px 20px;
}
#booking-guest .required-instructions {
  margin-top: -20px;
}
.login-toggle-wrapper {
  display: none;
}
.signed-in .login-toggle-wrapper {
  display: block;
}
.login-toggle-btn.expanded .svg-icon-down-arrow {
  transform: rotate(180deg);
}
.dob-fields {
  margin-left: 11px;
}
.signin-reg-wrapper {
  display: grid;
  grid-template-columns: 145px 1fr;
  margin-top: 20px;
}
.reg-link-wrapper {
  display: table;
}
.reg-link-wrapper span {
  display: table-cell;
  vertical-align: middle;
}
#login-msg {
    padding: 15px;
    max-width: 465px;
    margin: 0 auto;
}
@media screen and (min-width: 48em) {
  #cp-auth-container.grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(345px, 1fr));
    grid-gap: 1.88rem;
    padding: 15px;
  }
  .login-container {
    margin: 0 auto;
  }
  #booking-guest {
    padding: 30px 50px;
  }
  .signin-view-mobile {
    display: none;
  }
  .signin-view-wider {
    display: block;
  }
  .registered-guest {
    padding: 50px;
  }
  .login-btn-wrapper, .reg-link-wrapper {
    margin-top: 20px;
  }
  #login-msg {
    padding: 0 15px;
    margin: 0;
  }
}

/**
 * Retrive booking page styles
 */
.mb-bookings-list {
  list-style: none;
  max-width: 850px;
  margin: 0 auto;
  padding: 0 20px;
}
.mb-bookings-list li {
  border-bottom: solid 1px var(--neutral-medium);
  padding-bottom: 10px;
  margin-bottom: 20px;
}
a.mb-booking-item {
  display: flex;
}
a.mb-booking-item .mb-item-wrapper {
  width: 100%;
  padding-right: 15px;
  border: solid 1px transparent;
  border-right: none;
}
a.mb-booking-item:hover .mb-item-wrapper,
a.mb-booking-item:focus .mb-item-wrapper {
  background: var(--auxiliary-blue-extra-light);
  border: solid 1px var(--auxiliary-blue-light);
  border-right: none;
  border-radius: 4px 0 0 4px;
}
.mb-booking-title {
  padding: 12px 15px;
}
.mb-booking-number p {
  display: inline-block;
}
.mb-icon {
  background: var(--auxiliary-blue-extra-light);
  border-radius: 0 4px 4px 0;
  display: flex;
  align-items: center;
}
a.mb-booking-item:hover .mb-icon,
a.mb-booking-item:focus .mb-icon {
  background: var(--primary-blue);
}
.svg-icon-side-arrow {
  transform: rotate(-90deg);
  width: 35px;
  height: 35px;
  fill: var(--primary-blue);
}
a.mb-booking-item:hover .svg-icon-side-arrow,
a.mb-booking-item:focus .svg-icon-side-arrow {
  fill: var(--neutral-white);
}

/**
 * Login Form
 */
.login_dateStamp {
  overflow: initial;
}
.login_dateStamp > legend {
  padding-bottom: 0;
}
@media screen and (max-width: 399px) {
  .login_dateStamp .inline-field + .inline-field {
    margin: 0;
  }
}
